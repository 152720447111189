<template>
  <div class="vmc">
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Vehicle Mileage Check</p>
      </div>
    </div>
    <div class="box">
      <div class="columns is-vcentered">
        <div class="column is-narrow">
          <a class="has-text-info" @click="goToVmc('logo')">
            <img
              width="170px"
              :src="require('core/img/logos/experian/vmc.png')"
              alt="Experian VMC"
            />
          </a>
        </div>
        <div class="column">
          <p class="is-size-6-desktop is-size-7-tablet is-size-7-mobile field">
            <span>
              If you intend to retail this vehicle we advise that you complete a
              Vehicle Mileage Check by clicking here, or by emailing us at
              <a
                @click="track('click_email')"
                href="mailto:vmc.operations@uk.experian.com"
                >vmc.operations@uk.experian.com
              </a>
            </span>
          </p>
          <a
            @click="goToVmc('button')"
            class="is-size-6-desktop is-size-7-tablet is-size-7-mobile has-text-weight-semibold has-text-soft"
            data-test-id="autocheck-vmc-link"
          >
            Vehicle Mileage Check
            <span class="icon has-text-soft is-size-7-mobile">
              <i class="far fa-arrow-right" />
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AutoCheckVMC',
  props: {
    vrm: {
      type: String,
      required: true
    },
    vin: {
      type: String,
      required: true
    },
    mileage: {
      type: Number,
      required: false,
      default: () => 0
    },
    hasVmc: {
      type: Number,
      required: true
    }
  },
  methods: {
    track(action) {
      this.$mxp.track(`autocheck_VMC_${action}`)
    },
    goToVmc(source) {
      const { hasVmc, vin, vrm, mileage, track, $modal } = this
      track(`${source}_open_${hasVmc ? 'form' : 'upsell'}`)

      if (hasVmc) return $modal.open('vmc/VmcContainer', { vrm, vin, mileage })
      $modal.open('vmc/VmcUpsell')
    }
  }
}
</script>
